import { default as _91id_string_9398hphvNfysMeta } from "/directory/pages/[id_string].vue?macro=true";
import { default as _91id_93EqY4hMdW8DMeta } from "/directory/pages/category/[id].vue?macro=true";
import { default as indexLhN1aTpuKwMeta } from "/directory/pages/index.vue?macro=true";
import { default as newI41I6t8LcPMeta } from "/directory/pages/new.vue?macro=true";
import { default as _91id_93H6SPFZjeZJMeta } from "/directory/pages/type/[id].vue?macro=true";
export default [
  {
    name: _91id_string_9398hphvNfysMeta?.name ?? "id_string",
    path: _91id_string_9398hphvNfysMeta?.path ?? "/:id_string()",
    meta: _91id_string_9398hphvNfysMeta || {},
    alias: _91id_string_9398hphvNfysMeta?.alias || [],
    redirect: _91id_string_9398hphvNfysMeta?.redirect || undefined,
    component: () => import("/directory/pages/[id_string].vue").then(m => m.default || m)
  },
  {
    name: _91id_93EqY4hMdW8DMeta?.name ?? "category-id",
    path: _91id_93EqY4hMdW8DMeta?.path ?? "/category/:id()",
    meta: _91id_93EqY4hMdW8DMeta || {},
    alias: _91id_93EqY4hMdW8DMeta?.alias || [],
    redirect: _91id_93EqY4hMdW8DMeta?.redirect || undefined,
    component: () => import("/directory/pages/category/[id].vue").then(m => m.default || m)
  },
  {
    name: indexLhN1aTpuKwMeta?.name ?? "index",
    path: indexLhN1aTpuKwMeta?.path ?? "/",
    meta: indexLhN1aTpuKwMeta || {},
    alias: indexLhN1aTpuKwMeta?.alias || [],
    redirect: indexLhN1aTpuKwMeta?.redirect || undefined,
    component: () => import("/directory/pages/index.vue").then(m => m.default || m)
  },
  {
    name: newI41I6t8LcPMeta?.name ?? "new",
    path: newI41I6t8LcPMeta?.path ?? "/new",
    meta: newI41I6t8LcPMeta || {},
    alias: newI41I6t8LcPMeta?.alias || [],
    redirect: newI41I6t8LcPMeta?.redirect || undefined,
    component: () => import("/directory/pages/new.vue").then(m => m.default || m)
  },
  {
    name: _91id_93H6SPFZjeZJMeta?.name ?? "type-id",
    path: _91id_93H6SPFZjeZJMeta?.path ?? "/type/:id()",
    meta: _91id_93H6SPFZjeZJMeta || {},
    alias: _91id_93H6SPFZjeZJMeta?.alias || [],
    redirect: _91id_93H6SPFZjeZJMeta?.redirect || undefined,
    component: () => import("/directory/pages/type/[id].vue").then(m => m.default || m)
  }
]